

body, html {
  height: 100%;
  display: inherit;
}



.bg {
  
  background-image: url("Google_play_store_banner.png");
  height: 100vh;
  background-position: center;
  background-repeat: repeat-y;
  background-repeat: repeat-x;
  background-clip: content-box;
  background-size: cover;


}

.home{
  padding-top: 38%;
  color: black;
  font-weight: bold;
}

.social{
  position: left;
  margin: auto;
  margin: 25%;
  padding: auto;
  padding-right:80%;
  margin-bottom: 3%;
  width:  5px;
  color: blue;
}

